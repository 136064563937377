@layer base {
  .main {
  text-align: center;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-64);
  padding: 0;
  list-style: none;
  gap: var(--sk-space-20);
}

.logo {
  border-radius: var(--sk-radius-8);
}

@media (--sk-medium-viewport) {
  .list {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--sk-space-40);
  }
}

@media (--sk-large-viewport) {
  .list {
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
    max-width: 64em; /* 1024px */
  }

  .item {
    position: relative;
  }

  .item__withPermalink::before {
    content: "";
    display: none;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 10px 0;
    border-radius: var(--sk-radius-8);
    /* stylelint-disable color-function-notation */
    box-shadow: 0 8px 16px hsla(240deg, 1%, 47%, 20%);
    cursor: pointer;
  }

  .item:hover::before {
    display: block;
  }

  .logo {
    filter: grayscale(1);
    width: 80px;
    height: 80px;
  }

  .item:hover .logo {
    filter: grayscale(0);
  }
}

}