@layer base {
  .badge {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.button,
.heading {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.heading {
  padding: var(--sk-space-2) var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

.button:hover,
.button:active,
.button:focus-visible {
  background: linear-gradient(90.31deg, #513095 0.53%, #cb017a 99.47%);
}
}