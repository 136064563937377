@layer base {
  .card {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-content-gap: var(--sk-space-12);
  position: relative;
}

.headerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  margin-left: var(--sk-space-8);
  padding: var(--sk-space-4);
  border-radius: var(--sk-radius-4);
  background: var(--sk-color-white);
}

.logo:first-child {
  margin-left: calc(var(--sk-space-4) * -1);
}

.logoRow {
  z-index: var(--z-up-in-the-current-stacking-context); /* Above the card cover */
  margin-top: -40px;
  margin-bottom: calc(var(--sk-space-12) * -1);
}

.link {
  color: var(--sk-color-black);
  text-decoration: none;
}

.link:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.link::before {
  content: "";
  position: absolute;
  /* above the card and logoRow */
  z-index: calc(var(--z-up-in-the-current-stacking-context) + 1);
  inset: 0;
  cursor: pointer;
}

}