@layer base {
  .setCvAsDefault {
  margin-top: var(--sk-space-8);
}

.setCvAsDefaultTooltip {
  margin-left: var(--sk-space-8);
  vertical-align: bottom;
}

}