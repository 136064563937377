@layer base {
  .container {
  display: grid;
  gap: var(--sk-space-8);
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin: var(--sk-space-16) 0 var(--sk-space-8) 0;
  padding: 0;
  list-style-type: none;
}

.link {
  display: flex;
}

.link:focus-visible {
  border-radius: var(--sk-radius-4);
  outline: none;
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.partnerCompany {
  box-sizing: border-box;
  width: 100%;
  max-width: 110px;
  height: 100%;
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-space-4);
}

}