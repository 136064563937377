@layer base {
  .main {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-24);
  border-radius: var(--sk-radius-12);
  outline: 0;
  color: inherit;
  text-decoration: none;
  gap: var(--sk-space-8);
}

.main::before,
.main::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.main::before {
  z-index: var(--z-reset-in-the-current-stacking-context);
  inset: 0;
  background: linear-gradient(90.31deg, rgb(100 60 186 / 50%) 0.53%, rgb(255 0 153 / 50%) 99.47%);
}

.main::after {
  z-index: var(--z-reset-in-the-current-stacking-context);
  border-radius: calc(var(--sk-radius-12) - var(--sk-size-2));
  background: linear-gradient(90.31deg, #eae6f3, #fae1ef);
  inset: var(--sk-size-2);
}

.main:active::before,
.main:hover::before,
.main:focus-visible::before {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
}

.main:focus-visible {
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2);
}

.content, .icon {
  z-index: var(--z-up-in-the-current-stacking-context);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  place-items: flex-start start;
}

.content .badge {
  align-self: start;
}

.icon {
  min-width: var(--sk-size-20);
  padding: var(--sk-space-12);
}
}