@layer base {
  .title {
  margin-bottom: var(--sk-space-16);
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-12);
}

}