@layer modules, ui, base;
@layer base {
  .UserAvatar_userAvatar--default___OA31 {
  background-color: var(--sk-color-grey-100);
  color: var(--sk-color-black);
}

.UserAvatar_userAvatar--image__qvVLz {
  -o-object-fit: cover;
     object-fit: cover;
}

}
@layer base {
  .NewCv_setCvAsDefault__NFqtA {
  margin-top: var(--sk-space-8);
}

.NewCv_setCvAsDefaultTooltip__6GGp8 {
  margin-left: var(--sk-space-8);
  vertical-align: bottom;
}

}
@layer base {
  .Companies_main__X4NO3 {
  text-align: center;
}

.Companies_list__mHqg9 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--sk-space-64);
  padding: 0;
  list-style: none;
  gap: var(--sk-space-20);
}

.Companies_logo__M8A19 {
  border-radius: var(--sk-radius-8);
}

@media (min-width: 768px) {
  .Companies_list__mHqg9 {
    grid-template-columns: repeat(4, 1fr);
    gap: var(--sk-space-40);
  }
}

@media (min-width: 1312px) {
  .Companies_list__mHqg9 {
    grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
    max-width: 64em; /* 1024px */
  }

  .Companies_item__IVyad {
    position: relative;
  }

  .Companies_item__withPermalink__lzoaB::before {
    content: "";
    display: none;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 10px 0;
    border-radius: var(--sk-radius-8);
    /* stylelint-disable color-function-notation */
    box-shadow: 0 8px 16px hsla(240deg, 1%, 47%, 20%);
    cursor: pointer;
  }

  .Companies_item__IVyad:hover::before {
    display: block;
  }

  .Companies_logo__M8A19 {
    filter: grayscale(1);
    width: 80px;
    height: 80px;
  }

  .Companies_item__IVyad:hover .Companies_logo__M8A19 {
    filter: grayscale(0);
  }
}

}
@layer base {
  .Hero_wrapper__PMPii {
    background-color: var(--sk-color-black);
}

.Hero_heroPicture__RWdEn {
    display: none;
}

.Hero_title__wT8gq {
    margin-bottom: var(--sk-space-40);
    font-size: var(--sk-font-size-7) !important; /* 40px */
    line-height: var(--sk-line-heights-7) !important; /* 48px */
}

.Hero_preTitle__2jYtP {
    color: var(--sk-color-green-500);
}

.Hero_main__XnJgQ {
    --layout-size: 100%;
    position: relative;
    max-width: var(--layout-size);
    padding: var(--sk-space-80) var(--sk-space-16);
    color: var(--sk-color-white);
}


.Hero_description__nvR6N {
    font-size: 20px;
    line-height: 28px;
}

@media (min-width: 768px) {
    .Hero_main__XnJgQ {
        padding: 136px 104px var(--sk-space-64);
        overflow-x: clip;
    }

    .Hero_title__wT8gq {
        position: relative;
        z-index: var(--z-up-in-the-current-stacking-context);
        max-width: 520px;
        font-size: var(--sk-font-size-8) !important; /* 48px */
        line-height: var(--sk-line-heights-6) !important; /* 56px */
    }
    
    .Hero_description__nvR6N {
        max-width: 400px;
    }

    .Hero_heroPicture__RWdEn {
        display: block;
        position: absolute;
        right: -135px;
        bottom: -25px;
        width: 395px;
        height: 382px;
    }

    .Hero_heroPictureShorter__gW1rJ {
        right: 45px;
        bottom: -15px;
        width: 286px;
        height: 277px;
    }
}

@media (min-width: 1312px) {
    .Hero_main__XnJgQ {
        --layout-size: 77rem;
        width: 100%;
        max-width: var(--layout-size);
        margin: auto;
        padding: 136px 0 var(--sk-space-64);
    }

    .Hero_title__wT8gq {
        max-width: 650px;
        padding-left: var(--sk-space-48);
        font-size: 58px !important;
        line-height: var(--sk-line-heights-6) !important; /* 56px */
    }

    .Hero_heroPicture__RWdEn {
        position: absolute;
        top: 100px;
        right: 160px;
        width: 528px;
        height: 510px;
    }

    .Hero_heroPictureShorter__gW1rJ {
        right: 165px;
        bottom: -15px;
        width: 470px;
        height: 370px;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: left top;
           object-position: left top;
        border-radius: 14px;
    }

    .Hero_description__nvR6N {
        padding-left: var(--sk-space-48);
        font-size: 24px;
        line-height: 32px;
    }
}
}
@layer base {
  .Push_main__PDwqB {
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: var(--sk-space-16) var(--sk-space-24);
  border-radius: var(--sk-radius-12);
  outline: 0;
  color: inherit;
  text-decoration: none;
  gap: var(--sk-space-8);
}

.Push_main__PDwqB::before,
.Push_main__PDwqB::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.Push_main__PDwqB::before {
  z-index: var(--z-reset-in-the-current-stacking-context);
  inset: 0;
  background: linear-gradient(90.31deg, rgb(100 60 186 / 50%) 0.53%, rgb(255 0 153 / 50%) 99.47%);
}

.Push_main__PDwqB::after {
  z-index: var(--z-reset-in-the-current-stacking-context);
  border-radius: calc(var(--sk-radius-12) - var(--sk-size-2));
  background: linear-gradient(90.31deg, #eae6f3, #fae1ef);
  inset: var(--sk-size-2);
}

.Push_main__PDwqB:active::before,
.Push_main__PDwqB:hover::before,
.Push_main__PDwqB:focus-visible::before {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
}

.Push_main__PDwqB:focus-visible {
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2);
}

.Push_content__hmI9m, .Push_icon__mS1oI {
  z-index: var(--z-up-in-the-current-stacking-context);
}

.Push_content__hmI9m {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-8);
  place-items: flex-start start;
}

.Push_content__hmI9m .Push_badge__N8UxZ {
  align-self: start;
}

.Push_icon__mS1oI {
  min-width: var(--sk-size-20);
  padding: var(--sk-space-12);
}
}
@layer base {
  .AI_badge__eONwN {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.AI_button__F_G82,
.AI_heading__aZk8P {
  background: linear-gradient(90.31deg, #643cba 0.53%, #ff0099 99.47%);
  color: var(--sk-color-white);
}

.AI_heading__aZk8P {
  padding: var(--sk-space-2) var(--sk-space-4);
  border-radius: var(--sk-radius-4);
}

.AI_button__F_G82:hover,
.AI_button__F_G82:active,
.AI_button__F_G82:focus-visible {
  background: linear-gradient(90.31deg, #513095 0.53%, #cb017a 99.47%);
}
}
@layer base {
  .AsideCsSpotlight_card__IQnLy {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
  --sk-data-display-card-body-content-gap: var(--sk-space-12);
  position: relative;
}

.AsideCsSpotlight_headerImage__8LDgV {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.AsideCsSpotlight_logo__U7ZSK {
  margin-left: var(--sk-space-8);
  padding: var(--sk-space-4);
  border-radius: var(--sk-radius-4);
  background: var(--sk-color-white);
}

.AsideCsSpotlight_logo__U7ZSK:first-child {
  margin-left: calc(var(--sk-space-4) * -1);
}

.AsideCsSpotlight_logoRow___TtWg {
  z-index: var(--z-up-in-the-current-stacking-context); /* Above the card cover */
  margin-top: -40px;
  margin-bottom: calc(var(--sk-space-12) * -1);
}

.AsideCsSpotlight_link__Tc2VW {
  color: var(--sk-color-black);
  text-decoration: none;
}

.AsideCsSpotlight_link__Tc2VW:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.AsideCsSpotlight_link__Tc2VW::before {
  content: "";
  position: absolute;
  /* above the card and logoRow */
  z-index: calc(var(--z-up-in-the-current-stacking-context) + 1);
  inset: 0;
  cursor: pointer;
}

}
@layer base {
  .CsSpotlightLink_main__jGniI {
  /* above the card to make it clickable with the global link of the card */
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  z-index: 3;
}

}
@layer base {
  .AsidePartnersCompany_container__akxR3 {
  display: grid;
  gap: var(--sk-space-8);
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin: var(--sk-space-16) 0 var(--sk-space-8) 0;
  padding: 0;
  list-style-type: none;
}

.AsidePartnersCompany_link__mskzo {
  display: flex;
}

.AsidePartnersCompany_link__mskzo:focus-visible {
  border-radius: var(--sk-radius-4);
  outline: none;
  box-shadow:
    var(--sk-ressource-focus-ring-shadow-light-shadow-3),
    var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.AsidePartnersCompany_partnerCompany__ybIoZ {
  box-sizing: border-box;
  width: 100%;
  max-width: 110px;
  height: 100%;
  border: 1px solid var(--sk-color-grey-100);
  border-radius: var(--sk-space-4);
}

}
@layer base {
  .AsideUsefulLinks_title__FHQuJ {
  margin-bottom: var(--sk-space-16);
}

.AsideUsefulLinks_cardsContainer__dOXxe {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-12);
}

}
@layer base {
  .Aside_aside__G_fnN {
  display: flex;
  gap: var(--sk-space-40);
  grid-column: 1 / 5;
  flex-direction: column;
}

.Aside_divider__xGT_0 {
  margin-top: var(--sk-space-32);
}

@media (min-width: 768px) {
  .Aside_aside__G_fnN {
    grid-column: 9 / 13;
  }

  .Aside_divider__xGT_0 {
    display: none;
  }
}

@media (min-width: 80rem) {
  .Aside_aside__G_fnN {
    grid-column: 10 / 13;
  }
}

}
@layer base {
  .CardContentBoost_card__dYRFb {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
}

.CardContentBoost_card__dYRFb:hover {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

.CardContentBoost_cardFooter__p_XAU {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

/* Horizontal card in responsive */

@container --sk-card (min-width: 448px) {
  .CardContentBoost_cardCover__YF_gL {
    max-width: 33%;
  }
}

}
@layer base {
  .SchoolBadge_main__ZZ30J {
  display: flex;
  align-items: center;
  gap: var(--sk-space-12);
}

}
