@layer base {
  .userAvatar--default {
  background-color: var(--sk-color-grey-100);
  color: var(--sk-color-black);
}

.userAvatar--image {
  object-fit: cover;
}

}