@layer base {
  .card {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-100);
}

.card:hover {
  --sk-data-display-card-color-border-default-light: var(--sk-color-grey-200);
}

.cardFooter {
  display: flex;
  align-items: center;
  gap: var(--sk-space-16);
}

/* Horizontal card in responsive */

@container --sk-card (min-width: 448px) {
  .cardCover {
    max-width: 33%;
  }
}

}