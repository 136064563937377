@layer base {
  .aside {
  display: flex;
  gap: var(--sk-space-40);
  grid-column: 1 / 5;
  flex-direction: column;
}

.divider {
  margin-top: var(--sk-space-32);
}

@media (--sk-medium-viewport) {
  .aside {
    grid-column: 9 / 13;
  }

  .divider {
    display: none;
  }
}

@media (--major-viewport) {
  .aside {
    grid-column: 10 / 13;
  }
}

}